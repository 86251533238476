import Header from "../components/Header"
import Password from "../components/pages/Password";

export default function PasswordPage(){
    return(
        <>
            <Header
                heading="Reset your password"
                paragraph="Remembered your password? "
                linkName="Login"
                linkUrl="/"
            />
            <Password />
        </>
    )
}