import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import SignupPage from './pages/Signup';
import LoginPage from './pages/Login';
import PasswordPage from './pages/Password'
import Banner from "./components/Banner";
import {GoAlert} from "react-icons/go";

function App() {
  return (
      <div>
      <Banner message="Warning! This site is still in beta and does not fully function." icon={<GoAlert width={12} height={12} color={'#cb0c0c'} />} color=""/>
          <div className="min-h-full h-screen flex items-center justify-center py-10 px-4 sm:px-6 lg:px-8 base">
        <div className="max-w-md w-full space-y-8">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LoginPage/>} />
              <Route path="/signup" element={<SignupPage/>} />
              <Route path="/password" element={<PasswordPage/>} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
      </div>
  );
}

export default App;